/** 
 *------------------------------------------------------------------------------
 * @package       T3 Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 JoomlArt.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       JoomlArt, JoomlaBamboo, (contribute to this project at github 
 *                & Google group to become co-author)
 * @Google group: https://groups.google.com/forum/#!forum/t3fw
 * @Link:         http://t3-framework.org 
 *------------------------------------------------------------------------------
 */


// ------------------------------------------------------
// LEGACY NAVIGATION ELEMENTS
// ------------------------------------------------------
// T3 Note: Extend BS3 Dropdown Menu to multi level
// Dropdown Sub Menus
// ------------------

.dropdown-submenu {
  position: relative;
}

// Default dropdowns
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -3px;
  margin-left: 20px;
  padding: 10px 20px 20px;
}

.dropdown-submenu.open > .dropdown-menu {
  display: block;
}


// The Sub Menus
// ------------------
.dropdown-submenu > .dropdown-menu {
  //border-radius: @border-radius-base;
}

// Caret to indicate there is a submenu
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: darken(@dropdown-bg, 20%);
  margin-top: 5px;
  margin-right: -5px;
}

.dropdown-submenu.open > a:after {
  border-left-color: @dropdown-link-hover-color;
}

/*
//
// Hide the submenu on Touch Devices
@media screen and (max-width: @screen-xs-max) {
  .dropdown-submenu > .dropdown-menu {
    display: none !important;
  }
  .dropdown-submenu > a:after {
    display: none !important;
  }
}
*/